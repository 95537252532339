import React from "react";
import { Link, graphql } from "gatsby";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import GroupsForm from "../components/groups-form";
import Layout from "../components/layout";
import Seo from "../components/seo";

const GroupsTemplate = ({ data, pageContext: context }) => {
  const { title, main_title, introduction } = data.contentfulPage;

  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="h2-editorial">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="h3-editorial">{children}</h3>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noreferrer">
          {children}
        </a>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <Link to={`/${context.locale}/${node.data.target.slug}/`}>
          {children}
        </Link>
      ),
    },
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  return (
    <Layout>
      <Seo title={title} description={main_title} />
      <section className="section section-gradient">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              {main_title ? (
                <>
                  <p
                    className={`paragraph font-weight-bold text-center ${
                      data.contentfulHotel.spirit.slug === "travel"
                        ? "text-orange"
                        : "text-yellow"
                    }`}
                  >
                    {title}
                  </p>
                  <h1 className="text-center">{main_title}</h1>
                </>
              ) : (
                <h1 className="text-center">{title}</h1>
              )}
              {introduction &&
                renderRichText(introduction, {
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => (
                      <p className="lead text-center">{children}</p>
                    ),
                    [INLINES.HYPERLINK]: (node, children) => {
                      return (
                        <a
                          href={node.data.uri}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {children}
                        </a>
                      );
                    },
                    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                      return (
                        <Link
                          to={`/${context.locale}/${node.data.target.slug}/`}
                        >
                          {children}
                        </Link>
                      );
                    },
                  },
                })}
              <hr />
              <div className="row justify-content-center">
                {data.contentfulHotel.groups_form && (
                  <div className="col-12 mb-16 mb-md-30">
                    {renderRichText(
                      data.contentfulHotel.groups_form,
                      renderOptions
                    )}
                  </div>
                )}
                <div className="col-lg-9">
                  <GroupsForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default GroupsTemplate;

export const query = graphql`
  query ($id: String!, $slug: String!, $locale: String!, $hotel: String!) {
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(slug: { eq: $hotel }, node_locale: { eq: $locale }) {
      title
      address
      email
      phone
      booking_id
      place_id
      business_url
      groups_form {
        raw
      }
      hero_image {
        file {
          url
        }
      }
      slug
      spirit {
        slug
      }
      seo_site_hotel_meta_description
    }

    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      main_title
      introduction {
        raw
        references {
          contentful_id
          slug
        }
      }
      seo_content {
        raw
      }
    }
  }
`;
